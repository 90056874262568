import React from 'react'
import '../Styling/Footer.css'
import Linkedin from '../Images/linkedin.svg'
import Github from '../Images/github.svg'

function Footer() {
    return (
        <div className="footer_div">

            <div className="footer_container">

                <div className="footer_linksDiv">
                    <a className="footer_links" href="https://www.linkedin.com/in/anthony-jimenez-dev/" target="_blank" rel="noreferrer"> <img className="footer_icons" src={Linkedin} alt="" /> </a>
                    <a className="footer_links" href="https://github.com/Anthony-Developer" target="_blank" rel="noreferrer"> <img className="footer_icons" src={Github} alt="" /> </a>
                </div>

                <p className="footer_text"> © 2021 Anthony Jimenez </p>

            </div>
            
        </div>
    )
}

export default Footer
