import React from 'react'
import '../Styling/Projects.css'
import Img1 from '../Images/Projects/Amazon.svg'
import Img2 from '../Images/Projects/Messenger.svg'
import Img3 from '../Images/Projects/Spotify.svg'
import Img4 from '../Images/Projects/SoundSearch.svg'
import Img5 from '../Images/Projects/FMF.svg'

function Projects() {


    return (
        <div className="projects_div" id="projects">
            <h1 className="projects_header"> Projects </h1>

            <div className="projects_container">

                <div className="projects_cards">

                    <div className="projects_imgDiv">
                        <img className="projects_img" src={Img1} alt=""/>
                    </div>

                    <h2 className="projects_title"> Amazon Clone </h2>
                    <p className="projects_info"> An Amazon clone where users can get authenticated, have the ability to add and remove items to their cart cart with updated subtotal. Adding more features as we speak. </p>

                    <a className="projects_links" target="_blank" href="https://github.com/Anthony-Developer/Amazon-Clone" rel="noreferrer"> Github </a>
                        <br/>
                    <a className="projects_links" target="_blank" href="https://clone-a135f.web.app/" rel="noreferrer"> Live Site</a>
                    
                </div>

                <div className="projects_cards">

                    <div className="projects_imgDiv">
                        <img className="projects_img" src={Img2} alt=""/>
                    </div>

                    <h2 className="projects_title"> Facebook Messenger Clone </h2>
                    <p className="projects_info"> A messenger clone, a simple chat application with a global chat room. Users will be prompted to enter their name and all associated messages from the user will be linked to the user. </p>

                    <a className="projects_links" target="_blank" href="https://github.com/Anthony-Developer/messenger-clone" rel="noreferrer"> Github </a>
                        <br/>
                    <a className="projects_links" target="_blank" href="https://messenger-clone-4192f.web.app/" rel="noreferrer"> Live Site</a>               
                </div>

                <div className="projects_cards">

                    <div className="projects_imgDiv">
                        <img className="projects_img" src={Img3} alt=""/>
                    </div>

                    <h2 className="projects_title"> Spotify Clone </h2>
                    <p className="projects_info"> A Spotify clone in React, after logging in the app will get all the data from Spotify from user information to weekly discovery music. </p>

                    <a className="projects_links" target="_blank" href="https://github.com/Anthony-Developer/spotify-clone" rel="noreferrer"> Github </a>
                        <br/>
                    <a className="projects_links" target="_blank" href="https://spotify-clone-3756f.web.app/" rel="noreferrer"> Live Site</a>
                </div>

                <div className="projects_cards">

                    <div className="projects_imgDiv">
                        <img className="projects_img" src={Img4} alt=""/>
                    </div>

                    <h2 className="projects_title"> Sound Search </h2>
                    <p className="projects_info"> A site where users can come to discover new and trending songs, read biographies on their favorite artists and see their top tracks of all-time. </p>

                    <a className="projects_links" target="_blank" href="https://github.com/Anthony-Developer/Sound-Search" rel="noreferrer"> Github </a>
                        <br/>
                    <a className="projects_links" target="_blank" href="https://pages.git.generalassemb.ly/Anthony-Dev-GA/Sound-Search/" rel="noreferrer"> Live Site</a>
                </div>

                <div className="projects_cards">

                    <div className="projects_imgDiv">
                        <img className="projects_img" src={Img5} alt=""/>
                    </div>


                    <h2 className="projects_title"> Find Me Food </h2>
                    <p className="projects_info"> A place where users can search for local restaurants, or find recipes on food they may be interested in. </p>

                    <a className="projects_links" target="_blank" href="https://github.com/Anthony-Developer/Find-Me-Food" rel="noreferrer"> Github </a>
                        <br/>
                    <a className="projects_links" target="_blank" href="http://find-me-food.surge.sh/" rel="noreferrer"> Live Site</a>
                </div>
                


            </div>
            
        </div>
    )
}

export default Projects
