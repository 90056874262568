import React from 'react'
import '../Styling/About.css'

function About() {
    return (
        <div className="about_div" id="about">
            <div className="about_container">

                <div className="about_info">
                    <h2 className="about_title">Welcome</h2>

                    <p className="about_subtitle"> I am a versatile Software Developer with a passion to create awesome and innovative solutions. I believe education is self-empowerment, therefore I always strive to become better than I was yesterday. I take pride in my profession and have always given my all as I like my work to be a representation of me. I believe that logical and structural thinking are fundamental to creating meaningful solutions. As a life-long learner I tend to use my experiences and education to help me exceed in my personal and professional life, as I am motivated by personal growth. I'm currently seeking a Software Engineer position in a collaborative environment where growth and development are valued. </p>
                </div>
                
            </div>
        </div>
    )
}

export default About
