import React, { useState, useEffect } from 'react'
import '../Styling/Navbar.css'
import { FaBars } from 'react-icons/fa'
import {animateScroll as scroll} from 'react-scroll'
import { Link as LinkScroll } from 'react-scroll'

function Navbar({toggle}) {
    const [scrollNav, setScrollNav] = useState(false)

    // Changes Nav background from transparent to black after scroll of 80px
    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    }
    
    // Checking to see if user is scrolling
    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, [])

    const toggleHome = () => {
        scroll.scrollToTop()
    }

    return (
        <div className="navbar_div" style={{background: scrollNav ? '#0d0d0d' : 'transparent'}}>

            <div className="navbar_container">
                
                <LinkScroll className="navbar_logo"  onClick={toggleHome}> AYJZ </LinkScroll>
                    
                <div className="navbar_mobileIcon" onClick={toggle}>
                    <FaBars />
                </div>

                <ul className="navbar_menu">
                    <li className="navbar_item">
                        <LinkScroll className="navbar_links" to='about' smooth={true} duration={800} spy={true} exact='true' offset={-80}> About </LinkScroll>
                    </li>
                </ul>

                <ul className="navbar_menu">
                    <li className="navbar_item">
                        <LinkScroll className="navbar_links" to='skills' smooth={true} duration={800} spy={true} exact='true' offset={-80}> Skills </LinkScroll>
                    </li>
                </ul>

                <ul className="navbar_menu">
                    <li className="navbar_item">
                        <LinkScroll className="navbar_links" to='projects' smooth={true} duration={800} spy={true} exact='true' offset={-80}> Projects </LinkScroll>
                    </li>
                </ul>

                <div className="navbar_buttonDiv">

                    <LinkScroll className="navbar_button" to='contact' smooth={true} duration={800} spy={true} exact='true' offset={-80}> Contact Me </LinkScroll>

                </div>

            </div>
            
        </div>
    )
}

export default Navbar
