import React from 'react'
import '../Styling/Sidebar.css'
import { FaTimes } from 'react-icons/fa'
import {animateScroll as scroll} from 'react-scroll'
import {Link as LinkScroll} from 'react-scroll'

function Sidebar({isOpen, toggle}) {

    const toggleHome = () => {
        scroll.scrollToTop()
        toggle()
    }


    return (

        <div className="sidebar_div" onClick={toggle} style={{opacity: isOpen ? '100%' : '0', top: isOpen ? '0' : '-100%'}}>

            <div className="sidebar_iconDiv" >
                <FaTimes className="sidebar_icon" />
            </div>

            <div className="sidebar_container">
                <ul className="sidebar_menu">
                    <LinkScroll className="sidebar_links" to='/' onClick={toggleHome}> Home </LinkScroll>
                    <LinkScroll className="sidebar_links" to='about' onClick={toggle} smooth={true} duration={800} spy={true} exact='true' offset={-80} > About </LinkScroll>
                    <LinkScroll className="sidebar_links" to='skills' onClick={toggle} smooth={true} duration={800} spy={true} exact='true' offset={-80}> Skills </LinkScroll>
                    <LinkScroll className="sidebar_links" to='projects' onClick={toggle} smooth={true} duration={800} spy={true} exact='true' offset={-80}> Projects </LinkScroll>
                </ul>

                <div className="sidebar_buttonDiv">
                    <LinkScroll className="sidebar_button" to='contact' onClick={toggle} smooth={true} duration={800} spy={true} exact='true' offset={-80}> Contact Me </LinkScroll>
                </div>

            </div>
            
        </div>
    )
}

export default Sidebar
