import React, { useState, useEffect } from 'react'
import './App.css'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import ReactGA from "react-ga"
import Home from './Components/Home'
import Contact from './Components/Contact'

function App() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
      setIsOpen(!isOpen)
  }

  useEffect(() => {
    ReactGA.initialize('UA-187426111-1')
    ReactGA.pageview(window.location.pathname)
  }, [])


  return (
    <Router>

      <Route
          exact
          path='/'>
          <Home isOpen={isOpen} toggle={toggle} />
      </Route>

      <Route
          exact
          path='/contact'>
          <Contact isOpen={isOpen} toggle={toggle} />
      </Route>

  </Router>
  )
}

export default App
