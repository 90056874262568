import React from 'react'
import '../Styling/Home.css'
import About from './About'
import Footer from './Footer'
import Main from './Main'
import NavBar from './Navbar'
import Projects from './Projects'
import Sidebar from './Sidebar'
import Skills from './Skills'
import Contact from './Contact'

function Home({isOpen, toggle}) {
    return (
        <div>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <NavBar toggle={toggle} />
            <Main />
            <About />
            <Skills />
            <Projects />
            <Contact />
            <Footer />
        </div>
    )
}

export default Home
