import React from 'react'
import '../Styling/Contact.css'
import { TextField, Button, makeStyles } from '@material-ui/core'

function Contact({isOpen, toggle}) {

    const useStyles = makeStyles({
        root: {
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "#dfdfdf",
                color: "#dfdfdf"
            }, 
            "& .MuiInputBase-input": {
                color: "#dfdfdf"
            }
        },
        button: {
            backgroundColor: "#0d0d0d",
            color: '#dfdfdf',
            '&:hover': {
                backgroundColor: "#616161",
                color: '#dfdfdf'
            }
        }
      })

      const classes = useStyles()

    return (
        <div className="contact_div" id="contact">

            <div className="contact_container">
                <div className="contact_titleDiv">
                    <h1 className="contact_title"> Contact Me </h1>
                    <h3 className="contact_subtitle">Let's create something awesome together</h3>
                </div>

                <div className="contact_formDiv">
                    <form name="contact" method="post">
                        <input type="hidden" name="form-name" value="contact" />

                        <div className="contact_inputs">
                            <TextField
                                className={classes.root}
                                InputLabelProps={{style: { color: '#dfdfdf' }}}
                                name="name"
                                label="Name"
                                variant="outlined"
                                defaultValue=""
                                required
                            />
                        </div>

                        <div className="contact_inputs">
                            <TextField 
                                className={classes.root}
                                InputLabelProps={{style: { color: '#dfdfdf' }}}
                                name="email"
                                type="email"
                                label="Email"
                                variant="outlined"
                                defaultValue=""
                                required
                            />
                        </div>

                        <div className="contact_inputs">
                            <TextField 
                                className={classes.root}
                                InputLabelProps={{style: { color: '#dfdfdf' }}}
                                name="message"
                                label="Your Message"
                                variant="outlined"
                                defaultValue=""
                                multiline
                                rows={7}
                                required
                            />
                        </div>

                        <div className="contact_inputs">
                            <Button className={classes.button} variant="contained" type="submit"> Submit </Button>
                        </div>

                    </form>
                </div>
            </div>
            
        </div>
    )
}

export default Contact
