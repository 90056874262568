import React from 'react'
import '../Styling/Skills.css'
import Css from '../Images/Logos/Css.svg'
import Firebase from '../Images/Logos/Firebase.svg'
import Git from '../Images/Logos/Git.svg'
import Html from '../Images/Logos/HTML.svg'
import Javascript from '../Images/Logos/JavaScript.svg'
import Material from '../Images/Logos/Material.svg'
import Node from '../Images/Logos/Node.svg'
import Postgres from '../Images/Logos/PostgreSQL.svg'
import Rails from '../Images/Logos/Rails.svg'
import ReactLogo from '../Images/Logos/React.svg'


function Skills() {
    return (
        <div className="skills_div" id="skills">

            <div className="skills_container">

                <h1 className="skills_title"> My Skills </h1>

                <p className="skills_subtitle"> Here is a list of languages, frameworks, and technologies I am familiar with: </p>

                <div className="skills_imgDiv">
                
                    <div className="skills_imgCard">
                        <img className="skills_img" src={ReactLogo} alt='' />
                    </div>
                    <div className="skills_imgCard">
                        <img className="skills_imgLG" src={Javascript} alt='' />
                    </div>
                    <div className="skills_imgCard">
                        <img className="skills_img" src={Rails} alt='' />
                    </div>
                    <div className="skills_imgCard">
                        <img className="skills_imgLG" src={Postgres} alt='' />
                    </div>
                    <div className="skills_imgCard">
                        <img className="skills_img" src={Node} alt='' />
                    </div>
                    <div className="skills_imgCard">
                        <img className="skills_imgLG" src={Firebase} alt='' />
                    </div>
                    <div className="skills_imgCard">
                        <img className="skills_imgLG" src={Material} alt='' />
                    </div>
                    <div className="skills_imgCard">
                        <img className="skills_img" src={Css} alt='' />
                    </div>
                    <div className="skills_imgCard">
                        <img className="skills_img" src={Html} alt='' />
                    </div>
                    <div className="skills_imgCard">
                        <img className="skills_img" src={Git} alt='' />
                    </div>
                   
                    
                </div>

            </div>
            
        </div>
    )
}

export default Skills
