import React from 'react'
import '../Styling/Main.css'
import Particles from 'react-tsparticles'


function Main() {
    return (
        <div className="main_div">

            <div className="main_background">
            <Particles className="particles"
                    height="880px"
                    params={{
                        particles: {
                            number: {
                              value: 80,
                              density: {
                                enable: true,
                                value_area: 800
                              }
                            },
                            color: {
                              value: "#fff",
                              animation: {
                                enable: true,
                                speed: 20,
                                sync: true
                              }
                            },
                            shape: {
                              type: "circle"
                            },
                            opacity: {
                              value: 0.5
                            },
                            size: {
                              value: 6,
                              random: {
                                enable: true,
                                minimumValue: 3
                              },
                              animation: {
                                enable: true,
                                speed: 5,
                                minimumValue: 3,
                                sync: false
                              }
                            },
                            move: {
                              enable: true,
                              speed: 2,
                              direction: "none",
                              random: false,
                              straight: false,
                              out_mode: "out",
                              warp: true
                            }
                          },
                    }}  />
            </div>
            
            <div className="main_content">
                <h1 className="main_title"> Hello, my name is Anthony Jimenez. </h1>
                <p className="main_subtitle"> A Full-Stack Web Developer. </p>
            </div>

        </div>
    )
}

export default Main
